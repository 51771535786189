@use '../../settings/_variable' as v;
@use '../../settings/_mixin' as r;
@use '../../settings/_path' as p;

.p-top {
  // .p-top1 .
  &1 {
    padding-top: 6px;
    position: relative;

    @include r.sp {
      padding-top: 15px;
    }

    // .p-top1:after .
    &:after {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 77.9%;
      top: 0;
      left: 0;
      background-color: v.$color13;
      content: '';

      @include r.sp {
        height: 67.1%;
      }
    }

    // .p-top1 .c-box1 .
    .c-box1 {
      margin-bottom: 81px;

      @include r.sp {
        margin-bottom: 40px;
      }
    }

    .c-box1,
    .c-block1 {
      position: relative;
      z-index: 1;
    }
    .c-list1 {
      &__date {
        line-height: 1.3;
      }
      &__title {
        white-space: normal;
        overflow: visible;
        line-height: 1.5;
      }
    }
  }

  // .p-top2 .
  &2 {
    padding: 80px 0 227px;

    @include r.sp {
      padding: 39px 0 114px;
    }

    // .p-top2 .c-title1 .
    .c-title1 {
      margin-bottom: 81px;

      @include r.sp {
        margin-bottom: 41px;
      }
    }
  }

  &3 {
    position: relative;
    z-index: 1;
    background-color: v.$color3;

    // .p-top3 .c-title1 .
    .c-title1 {
      top: -63px;
      margin-bottom: 24px;

      @include r.sp {
        top: -33px;
        margin-bottom: 32px;
      }
    }

    // .p-top3 .c-block2 .
    .c-block2 {
      margin-top: 3px;

      @include r.sp {
        margin-top: 80px;
      }
    }
  }

  // .p-top4 .
  &4 {
    padding-top: 83px;

    @include r.sp {
      padding-top: 40px;
    }

    // .p-top4 .c-title1 .
    .c-title1 {
      margin-bottom: 61px;

      @include r.sp {
        margin-bottom: 31px;
      }
    }

    // .p-top4 .c-btn1 .
    .c-btn1 {
      margin-top: 10px;

      @include r.sp {
        margin-top: 30px;
      }
    }
  }

  // .p-top5 .
  &5 {
    padding: 80px 0 160px;

    @include r.sp {
      padding: 41px 0 81px;
    }

    // .p-top5 .c-title1 .
    .c-title1 {
      margin-bottom: 61px;

      @include r.sp {
        margin-bottom: 25px;
      }
    }

    // .p-top5 .c-btn1 .
    .c-btn1 {
      margin-top: 24px;
    }
  }
}
